import React from "react";
// react components for routing the app without refresh
import { Link } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import MenuIcon from "@material-ui/icons/Menu";

// core components
import headerStyle from "assets/jss/ev/components/headerStyle.jsx";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.headerColorChange = this.headerColorChange.bind(this);
  }
  componentDidMount() {
    if (this.props.changeColorOnScroll) {
      window.addEventListener("scroll", this.headerColorChange);
    }
  }
  headerColorChange() {
    const { classes, color, changeColorOnScroll } = this.props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  }
  componentWillUnmount() {
    if (this.props.changeColorOnScroll) {
      window.removeEventListener("scroll", this.headerColorChange);
    }
  }
  render() {
    const {
      classes,
      color,
      topBarLinks,
      rightLinks,
      leftLinks,
      brand,
      fixed,
      absolute,
      menuOpen,
      handleMenuToggle
    } = this.props;
    const topAppBarClasses = classNames({
      [classes.topBar]: true,
      [classes.appBar]: true,
      [classes[color]]: color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed
    });
    const appBarClasses = classNames({
      [classes.appBar]: true,
      [classes[color]]: color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed,
      [classes.topAppBarExists]: topBarLinks
    });
    const brandComponent = (
      <Link to="/">
        <Button className={classes.title}>{brand}</Button>
      </Link>
    );

    const topAppBar = (
      <AppBar className={topAppBarClasses}>
        <Toolbar
          variant="dense"
          className={classes.topbarContainer}
          disableGutters={!menuOpen}
        >
          <div className={classes.flex}>{topBarLinks}</div>
        </Toolbar>
        <Divider />
      </AppBar>
    );

    return (
      <div>
        {topBarLinks !== undefined ? topAppBar : null}
        <AppBar className={appBarClasses}>
          <Toolbar className={classes.container} disableGutters={!menuOpen}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={handleMenuToggle}
              className={classNames(
                classes.menuButton,
                menuOpen && classes.hide
              )}
            >
              <MenuIcon />
            </IconButton>
            {leftLinks !== undefined ? brandComponent : null}
            <div className={classes.flex}>
              {leftLinks !== undefined ? (
                <Hidden xsDown implementation="css">
                  {leftLinks}
                </Hidden>
              ) : (
                brandComponent
              )}
            </div>
            {rightLinks}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "dark"
  ]),
  topBarLinks: PropTypes.node,
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.object,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  menuOpen: PropTypes.bool.isRequired,
  handleMenuToggle: PropTypes.func.isRequired,
  // this will cause the sidebar to change the color from
  // this.props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // this.props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "dark"
    ]).isRequired
  })
};

export default withStyles(headerStyle)(Header);
