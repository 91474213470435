import { defaultFont } from "assets/jss/ev.jsx";

import tooltip from "assets/jss/ev/tooltipsStyle.jsx";

const headerLinksStyle = theme => ({
  list: {
    ...defaultFont,
    fontSize: "14px",
    margin: "2.2em 0 0 1.6em",
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "inherit"
  },
  topbarlist: {
    ...defaultFont,
    fontSize: "14px",
    margin: "0",
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "inherit"
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  topBarListItem: {
    float: "right",
    [theme.breakpoints.down("xs")]: {
      display: "inherit"
    }
  },
  listItemText: {
    padding: "0 !important"
  },
  navLink: {
    color: "#435f77!important",
    position: "relative",
    background: "transparent",
    padding: "0.9375rem 1.375rem",
    fontWeight: "500",
    fontSize: "18px",
    textTransform: "uppercase",
    border: "none",
    boxShadow: "none",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "#767676!important",
      background: "transparent",
      border: "none",
      boxShadow: "none"
    }
  },
  topBarNavLink: {
    textTransform: "none",
    fontSize: "1rem",
    fontWeight: "400",
    "&:hover,&:focus": {
      color: "inherit",
      background: "transparent",
      textDecoration: "underline"
    }
  },
  notificationNavLink: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    top: "4px"
  },
  registerNavLink: {
    top: "3px",
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex"
  },
  navLinkActive: {
    color: "inherit",
    backgroundColor: "rgba(255, 255, 255, 0.1)"
  },
  icons: {
    width: "20px",
    height: "20px",
    marginRight: "3px"
  },
  socialIcons: {
    position: "relative",
    fontSize: "20px !important",
    marginRight: "4px"
  },
  dropdownLink: {
    "&,&:hover,&:focus": {
      color: "inherit",
      textDecoration: "none",
      display: "block",
      padding: "10px 20px"
    }
  },
  ...tooltip,
  marginRight5: {
    marginRight: "5px"
  }
});

export default headerLinksStyle;
