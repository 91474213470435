import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import moment from "moment";
import { IntlProvider, addLocaleData } from "react-intl";
import enLocaleData from "react-intl/locale-data/en";
import frLocaleData from "react-intl/locale-data/fr";

import enMessages from "translations/en.json";
import frMessages from "translations/fr.json";
import indexRoutes from "routes/index.jsx";

import Layout from "views/Layout/Main";
import ErrorBoundary from "components/_misc/ErrorBoundary";

import "assets/scss/ev.css?v=1.1.0";

const hist = createBrowserHistory();

let locale = "en";
let messages;

const getLocale = () => {
  if (document.location.hostname === "avoixegales.playmc2.com") {
    locale = "fr";
    messages = frMessages;
  } else {
    locale = "en";
    messages = enMessages;
  }
};

const setupLocale = () => {
  // Add the translation messages
  addLocaleData([...enLocaleData, ...frLocaleData]);

  // Set the Moment locale globally
  // See: http://momentjs.com/docs/#/i18n/changing-locale/
  moment.locale(locale);
};

const App = () => {
  getLocale();
  setupLocale();
  return (
    <IntlProvider
      key={locale}
      locale={locale}
      messages={messages}
      defaultLocale="en"
    >
      <Router history={hist}>
        <ErrorBoundary>
          <Layout>
            <Switch>
              {indexRoutes.map((prop, key) => {
                return (
                  <Route
                    path={prop.path}
                    key={key}
                    component={prop.component}
                  />
                );
              })}
            </Switch>
          </Layout>
          <Route path="/" component={ScrollToTop} />
          <Route path="/" component={Analytics} />
        </ErrorBoundary>
      </Router>
    </IntlProvider>
  );
};

// scroll to top on route change
class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }
  render() {
    return null;
  }
}

// Track Google Analytics page view for every route
// https://github.com/react-ga/react-ga/issues/122#issuecomment-319546248
const Analytics = ({ location }) => {
  const page = location.pathname + location.search;
  ReactGA.set({ page });
  ReactGA.pageview(page);
  return null;
};

export default App;
