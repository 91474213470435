import React from "react";
import PropTypes from "prop-types";
import {
  injectIntl,
  intlShape,
  FormattedMessage,
  FormattedDate
} from "react-intl";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Chip from "@material-ui/core/Chip";
import mapStyle from "assets/jss/ev/components/mapStyle.jsx";

function MapInfo({ ...props }) {
  const { type, data, classes, intl } = props;
  const mediaUrl = () => {
    if (type === "action") {
      return data.mediaUrl;
    } else if (type === "playlist") {
      return data.imageUrl;
    }
  };

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div>
      <DialogTitle className={classes.dialogTitle} id="responsive-dialog-title">
        {type === "action" && (
          <div>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/mc2-equalvoice.appspot.com/o/web%2Ficons%2Fpin_action.png?alt=media&token=bd7584da-7cef-4500-ae61-ed3efd271dc4"
              alt="Microaction"
              height="25px"
            />{" "}
            <FormattedMessage id="MapInfo.microaction" />
          </div>
        )}
        {type === "playlist" && (
          <div>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/mc2-equalvoice.appspot.com/o/web%2Ficons%2Fpin_custom_playlist.png?alt=media&token=14e0cf9c-fee8-409c-8a50-3ef6cfab1757"
              alt="Campaign"
              height="25px"
            />{" "}
            <FormattedMessage id="MapInfo.campaign" />
          </div>
        )}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <List>
          {type === "playlist" && (
            <ListItem button>
              <ListItemText
                primary={<FormattedMessage id="MapInfo.campaignTitle" />}
                secondary={intl.locale === "en" ? data.title.en : data.title.fr}
              />
            </ListItem>
          )}
          <Divider />
          <ListItem button>
            {type === "action" && (
              <ListItemText
                primary={<FormattedMessage id="MapInfo.actionTakenBy" />}
                secondary={`${data.firstName} ${data.lastName}`}
              />
            )}
            {type === "playlist" && (
              <ListItemText
                primary={<FormattedMessage id="MapInfo.campaignDescription" />}
                secondary={intl.locale === "en" ? data.desc.en : data.desc.fr}
              />
            )}
          </ListItem>
          <Divider />
          <ListItem button>
            {type === "action" && (
              <ListItemText
                primary={<FormattedMessage id="MapInfo.actionLocation" />}
                secondary={data.location.title}
              />
            )}
            {type === "playlist" && (
              <ListItemText
                primary={<FormattedMessage id="MapInfo.campaignLocation" />}
                secondary={data.location.address}
              />
            )}
          </ListItem>
          <Divider />
          <ListItem button>
            {type === "action" && (
              <ListItemText
                primary={<FormattedMessage id="MapInfo.actionTakenOn" />}
                secondary={
                  <FormattedDate
                    value={new Date(data.timestamp)}
                    year="2-digit"
                    month="2-digit"
                    day="2-digit"
                  />
                }
              />
            )}
            {type === "playlist" && (
              <ListItemText
                primary={<FormattedMessage id="MapInfo.campaignType" />}
                secondary={capitalizeFirstLetter(data.type)}
              />
            )}
          </ListItem>
          <Divider />
          <ListItem button>
            {type === "playlist" && (
              <div>
                <ListItemText
                  primary={<FormattedMessage id="MapInfo.tags" />}
                />
                {Object.keys(data.tags).map((tag, index) => {
                  return (
                    <Chip
                      key={`${tag}${index}`}
                      label={`#${tag}`}
                      variant="outlined"
                    />
                  );
                })}
              </div>
            )}
          </ListItem>
          <Divider />
        </List>
        <Card>
          <CardActionArea className={classes.cardActionArea}>
            <CardMedia
              image={mediaUrl()}
              title={`${capitalizeFirstLetter(type)} Media`}
              className={classes.cardMedia}
            />
            <CardContent>
              <Typography gutterBottom variant="display1" component="h2">
                {type === "action" && (
                  <FormattedMessage
                    id="MapInfo.actionMessage"
                    defaultMessage="Way to go, {name}!"
                    values={{
                      name: data.firstName
                    }}
                  />
                )}
              </Typography>
              <Typography component="p">
                {type === "playlist" &&
                  intl.locale === "en" &&
                  data.bio &&
                  data.bio.en}
                {type === "playlist" &&
                  intl.locale === "fr" &&
                  data.bio &&
                  data.bio.fr}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </DialogContent>
    </div>
  );
}

MapInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};

export default withStyles(mapStyle)(injectIntl(MapInfo));
