import React from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import ChartistGraph from "react-chartist";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import leaderPageStyle from "assets/jss/ev/views/leaderPage.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

// Sections for this page
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import TopBarLinks from "components/Header/TopBarLinks.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import SectionBasics from "../Components/Sections/SectionBasics.jsx";
import SectionNavbars from "../Components/Sections/SectionNavbars.jsx";
import SectionTabs from "../Components/Sections/SectionTabs.jsx";
import SectionPills from "../Components/Sections/SectionPills.jsx";
import SectionNotifications from "../Components/Sections/SectionNotifications.jsx";
import SectionTypography from "../Components/Sections/SectionTypography.jsx";
import SectionJavascript from "../Components/Sections/SectionJavascript.jsx";
import SectionExamples from "../Components/Sections/SectionExamples.jsx";
import { dailySalesChart } from "variables/charts.jsx";

import brandLogoEn from "assets/img/ev-logo-full.png";
import brandLogoFr from "assets/img/ev-logo-full-fr.png";

const dashboardRoutes = [];

class LeaderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterDrawerOpen: false,
      checkedFilters: []
    };
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
  }

  /* 
    @@ DEVELOPER NOTE on MapFilters implementation @@
      To see some basic examples of Krassi's original filtering code...
      Check out https://gitlab.com/krassik/learn-map/blob/multifacet/src/index.js

      To see how we will accomplish this using our re-base Firebase/Firestore implementation and queries...
      Check out the docs about bindCollection() and using query={} at 
      https://github.com/tylermcginnis/re-base#bindcollectionreforpath-options AND
      https://github.com/tylermcginnis/re-base#using-firestore-queries
  
    @@ NOTE: @@ We should be putting our fireStoreBase() code for filtering here in LeaderPage and 
      passing down as props into the MapFilters component, keeping this component where data is loaded and passed,
      and MapFilters pure.

  */

  handleDrawerToggle() {
    this.setState({ filterDrawerOpen: !this.state.filterDrawerOpen });
  }

  getBrandLogo() {
    if (this.props.intl.locale === "fr") {
      return brandLogoFr;
    } else {
      return brandLogoEn;
    }
  }

  render() {
    const { classes } = this.props;
    const { filterDrawerOpen, checkedFilters } = this.state;

    const isChecked = key => {
      return checkedFilters.indexOf(key) > -1;
    };

    const brandImage = (
      <img
        src={this.getBrandLogo()}
        alt="Equal Voice"
        className={classNames(classes.imgFluid, classes.brandImage)}
      />
    );
    return (
      <div>
        <Header
          classes={{
            title: classes.header,
            appBar: classNames(classes.appBar, {
              [classes.appBarShift]: filterDrawerOpen
            })
          }}
          routes={dashboardRoutes}
          brand={brandImage}
          leftLinks={<HeaderLinks />}
          topBarLinks={<TopBarLinks />}
          handleMenuToggle={this.handleDrawerToggle}
          menuOpen={filterDrawerOpen}
        />
        <nav className={classes.drawer}>
          {/* The implementation can be swap with js to avoid SEO duplication of links. */}
          <Sidebar
            filterDrawerOpen={filterDrawerOpen}
            handleDrawerToggle={this.handleDrawerToggle}
            className={classes.drawer}
            open
            variant="persistent"
            checked={checkedFilters}
            isChecked={isChecked}
          />
        </nav>
        <div
          className={classNames(classes.content, {
            [classes.contentShift]: filterDrawerOpen
          })}
        >
          <Parallax image={require("assets/img/bg.jpg")}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem>
                  <div className={classes.brand}>
                    <h1 className={classes.title}>Equal Voice.</h1>
                    <h3 className={classes.subtitle}>
                      React stack featuring React Router, Code linting, custom
                      Material-UI components, Firebase, and Firestore for Equal
                      Voice.
                    </h3>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </Parallax>

          <div
            className={classNames(classes.main, classes.mainRaised, {
              [classes.contentShift]: filterDrawerOpen
            })}
          >
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <CustomTabs
                  title="Tasks:"
                  headerColor="primary"
                  tabs={[
                    {
                      tabName: "Bugs",
                      tabIcon: BugReport,
                      tabContent: (
                        <Card chart>
                          <CardHeader color="success">
                            <ChartistGraph
                              className="ct-chart"
                              data={dailySalesChart.data}
                              type="Line"
                              options={dailySalesChart.options}
                              listener={dailySalesChart.animation}
                            />
                          </CardHeader>
                          <CardBody>
                            <h4 className={classes.cardTitle}>Daily Sales</h4>
                            <p className={classes.cardCategory}>
                              <span className={classes.successText}>
                                <ArrowUpward
                                  className={classes.upArrowCardCategory}
                                />{" "}
                                55%
                              </span>{" "}
                              increase in today sales.
                            </p>
                          </CardBody>
                          <CardFooter chart>
                            <div className={classes.stats}>
                              <AccessTime /> updated 4 minutes ago
                            </div>
                          </CardFooter>
                        </Card>
                      )
                    },
                    {
                      tabName: "Website",
                      tabIcon: Code,
                      tabContent: (
                        <Card chart>
                          <CardHeader color="success">
                            <ChartistGraph
                              className="ct-chart"
                              data={dailySalesChart.data}
                              type="Line"
                              options={dailySalesChart.options}
                              listener={dailySalesChart.animation}
                            />
                          </CardHeader>
                          <CardBody>
                            <h4 className={classes.cardTitle}>Daily Sales</h4>
                            <p className={classes.cardCategory}>
                              <span className={classes.successText}>
                                <ArrowUpward
                                  className={classes.upArrowCardCategory}
                                />{" "}
                                55%
                              </span>{" "}
                              increase in today sales.
                            </p>
                          </CardBody>
                          <CardFooter chart>
                            <div className={classes.stats}>
                              <AccessTime /> updated 4 minutes ago
                            </div>
                          </CardFooter>
                        </Card>
                      )
                    },
                    {
                      tabName: "Server",
                      tabIcon: Cloud,
                      tabContent: (
                        <Card chart>
                          <CardHeader color="success">
                            <ChartistGraph
                              className="ct-chart"
                              data={dailySalesChart.data}
                              type="Line"
                              options={dailySalesChart.options}
                              listener={dailySalesChart.animation}
                            />
                          </CardHeader>
                          <CardBody>
                            <h4 className={classes.cardTitle}>Daily Sales</h4>
                            <p className={classes.cardCategory}>
                              <span className={classes.successText}>
                                <ArrowUpward
                                  className={classes.upArrowCardCategory}
                                />{" "}
                                55%
                              </span>{" "}
                              increase in today sales.
                            </p>
                          </CardBody>
                          <CardFooter chart>
                            <div className={classes.stats}>
                              <AccessTime /> updated 4 minutes ago
                            </div>
                          </CardFooter>
                        </Card>
                      )
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
            <SectionBasics />
            <SectionTabs />
            <SectionPills />
            <SectionTypography />
            <SectionJavascript />
          </div>
        </div>
      </div>
    );
  }
}

LeaderPage.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  container: PropTypes.object,
  filters: PropTypes.array,
  intl: intlShape
};

export default withStyles(leaderPageStyle)(injectIntl(LeaderPage));
