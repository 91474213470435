import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import * as serviceWorker from "./serviceWorker";

import App from "./views/App";

console.log("EV Map env:", process.env.NODE_ENV);
console.log("EV Map project:", process.env.REACT_APP_ENV);

// Google Analytics
// https://github.com/react-ga/react-ga#api
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);

// Sentry
// https://docs.sentry.io/clients/javascript/integrations/react/
window.Raven.config(process.env.REACT_APP_SENTRY_RAVEN_TRACKING_URL).install();

// render the App component to our document root with React and Firestore & Firebase providers
ReactDOM.render(<App />, document.getElementById("root"));

// FOr the app to work offline and load faster, we can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
