import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons

import drawerStyle from "assets/jss/ev/components/drawerStyle.jsx";

class CustomDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false
    };
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
  }
  handleDrawerToggle() {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  }

  render() {
    const {
      classes,
      links,
      anchor,
      variant,
      onClose,
      open,
      DrawerTitle,
      ...rest
    } = this.props;

    return (
      <Drawer
        variant={variant}
        anchor={anchor}
        open={open}
        classes={classes}
        onClose={onClose}
        {...rest}
      >
        {DrawerTitle && (
          <div>
            <DrawerTitle />
            <Divider />
          </div>
        )}
        <div className={classes.appResponsive}>{links}</div>
      </Drawer>
    );
  }
}

CustomDrawer.defaultProps = {
  anchor: "left",
  variant: "temporary",
  open: false,
  classes: drawerStyle.drawerPaper
};

CustomDrawer.propTypes = {
  anchor: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  links: PropTypes.node,
  DrawerTitle: PropTypes.func,
  variant: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default withStyles(drawerStyle)(CustomDrawer);
