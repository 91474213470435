import { title, container, drawerWidth, primaryColor } from "assets/jss/ev.jsx";
import customCheckboxRadioSwitch from "assets/jss/ev/customCheckboxRadioSwitch.jsx";
import typographyStyle from "assets/jss/ev/views/componentsSections/typographyStyle.jsx";
import modalStyle from "assets/jss/ev/modalStyle.jsx";
import tooltipsStyle from "assets/jss/ev/tooltipsStyle.jsx";
import popoverStyles from "assets/jss/ev/popoverStyles.jsx";

const mapFiltersStyle = theme => ({
  container,
  ...customCheckboxRadioSwitch,
  ...typographyStyle,
  paper: {
    boxShadow: "none"
  },
  nested: {
    paddingLeft: "12px"
  },
  checked: {
    color: primaryColor + "!important"
  },
  checkedIcon: {
    width: "20px",
    height: "20px",
    border: "1px solid rgba(0, 0, 0, .54)",
    backgroundColor: "#dddddd",
    borderRadius: "3px"
  },
  uncheckedIcon: {
    width: "0px",
    height: "0px",
    padding: "9px",
    border: "1px solid rgba(0, 0, 0, .54)",
    borderRadius: "3px"
  },
  section: {
    padding: "10px 0"
  },
  title: {
    ...title,
    marginBottom: "5px",
    marginTop: "10px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center",
    fontWeight: 500
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    /* marginTop: "180px", */ // turning off for unfixed header
    width: drawerWidth,
    borderTop: "1px solid #ccc",
    boxShadow: "none",
    elevation2: {
      boxShadow: "none"
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 0
    }
  },
  listItem: {
    cursor: "pointer",
    textTransform: "capitalize"
  },
  subHeading: {
    fontSize: "1.1em",
    textTransform: "capitalize"
  },
  ...modalStyle,
  ...tooltipsStyle,
  ...popoverStyles
});

export default mapFiltersStyle;
