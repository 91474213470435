import LandingPage from "views/LandingPage/LandingPage.jsx";
import LeaderPage from "views/LeaderPage/LeaderPage.jsx";
import Components from "views/Components/Components.jsx";
// import MapPage from "views/MapPage/MapPage.jsx";

var indexRoutes = [
  // { path: "/map", name: "MapPage", component: MapPage },
  { path: "/components", name: "Components", component: Components },
  { path: "/leaderboard", name: "LeaderPage", component: LeaderPage },
  { path: "/", name: "LandingPage", component: LandingPage }
];

export default indexRoutes;
