import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import NewMap from "components/Map/NewMap.jsx";

// import data stores
import { firestoreBase } from "config/firebase";

import mapSection from "assets/jss/ev/views/landingPageSections/mapSection.jsx";

class MapSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      playlists: [],
      checked: [0],
      loading: true,
      filters: []
    };
  }

  componentDidMount() {
    this.actionsRef = firestoreBase.bindCollection("prodSubmittedActions", {
      context: this,
      state: "actions",
      withRefs: true,
      query: ref => ref.where("state", "==", "verified"), // filter by verified actions only
      then() {
        this.setState({ loading: false });
      }
    });
    this.playlistsRef = firestoreBase.bindCollection("prodPlaylists", {
      context: this,
      state: "playlists",
      withRefs: true,
      then() {
        this.setState({ loading: false });
      }
    });
  }

  componentDidUpdateNoUsed() {
    const filters = this.props;
    if (typeof filters[1] !== "undefined") {
      console.log("updating data filters");
      this.actionsRef = firestoreBase.bindCollection("prodSubmittedActions", {
        context: this,
        state: "actions",
        withRefs: true,
        query: filters.map(filter => {
          return filter.where("location.address", "==", filter);
        }),
        then() {
          this.setState({ loading: false });
        }
      });
      this.playlistsRef = firestoreBase.bindCollection("prodPlaylists", {
        context: this,
        state: "playlists",
        withRefs: true,
        query: filters.map(filter => {
          return filter.where("location.address", "==", filter);
        }),
        then() {
          this.setState({ loading: false });
        }
      });
    }
  }

  componentWillUnmount() {
    firestoreBase.removeBinding(this.actionsRef);
    firestoreBase.removeBinding(this.playlistsRef);
  }

  render() {
    const { classes, filters } = this.props;
    const { actions, playlists } = this.state;
    return (
      <NewMap
        className={classes.section}
        actions={actions}
        playlists={playlists}
        filters={filters}
      />
    );
  }
}

MapSection.propTypes = {
  classes: PropTypes.object.isRequired,
  filters: PropTypes.array
};

export default withStyles(mapSection)(MapSection);
