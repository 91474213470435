import Firebase from "@firebase/app";
import "@firebase/database";
import "@firebase/firestore";
import Rebase from "re-base";

// connect the app to firebase project
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "mc2-equalvoice",
  databaseURL:
    process.env.REACT_APP_FIREBASE_URL ||
    "https://mc2-equalvoice.firebaseio.com"
};

// connect the app to firestore project
const firestoreConfig = {
  apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
  authDomain: process.env.REACT_APP_FIRESTORE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIRESTORE_PROJECT_ID || "mc2-equalvoice-map",
  databaseURL:
    process.env.REACT_APP_FIRESTORE_URL ||
    "https://mc2-equalvoice-map.firebaseio.com"
};

// Initialize Firestore app
const firestoreInit = Firebase.initializeApp(firestoreConfig, "firestore");

// Retrieve the firestore database.
const firestoreDB = firestoreInit.firestore();
// temporary config to squash error date warning
// TODO - remove once this is the firebase default behavior
// https://firebase.google.com/docs/reference/js/firebase.firestore.Settings#~timestampsInSnapshots
firestoreInit.firestore().settings({ timestampsInSnapshots: true });

// Initialize RealTime Firebase app
const firebaseInit = Firebase.initializeApp(firebaseConfig, "firebase");

// Retrieve the database.
const firebaseDB = firebaseInit.database();

//  Export the Rebase stores
export const firestoreBase = Rebase.createClass(firestoreDB);
export const firebaseBase = Rebase.createClass(firebaseDB);
