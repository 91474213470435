/*eslint-disable*/
import React from "react";
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.jsx";

// @material-ui/icons

// core components

import headerLinksStyle from "assets/jss/ev/components/headerLinksStyle.jsx";

function TopBarLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.topbarlist}>
      <ListItem className={classNames(classes.listItem, classes.topBarListItem)}>
        <Button
          href="https://avoixegales.playmc2.com"
          color="transparent"
          text="text"
          disableRipple
          className={classNames(classes.navLink, classes.topBarNavLink)}
        >
            <FormattedMessage id="Header.french" />
        </Button>
      </ListItem>
      <ListItem className={classNames(classes.listItem, classes.topBarListItem)}>
        <Button
          href="https://equalvoice.playmc2.com"
          color="transparent"
          text="text"
          disableRipple
          className={classNames(classes.navLink, classes.topBarNavLink)}
        >
            <FormattedMessage id="Header.english" />
        </Button>
      </ListItem>
      <ListItem className={classNames(classes.listItem, classes.topBarListItem)}>
        <Button
          href="https://equalvoice.nationbuilder.com"
          color="transparent"
          text="text"
          disableRipple
          className={classNames(classes.navLink, classes.topBarNavLink)}
          >
            <FormattedMessage id="Header.home" />
        </Button>
      </ListItem>
    </List>
  );
}

export default withStyles(headerLinksStyle)(TopBarLinks);
