import { title, container, drawerWidth } from "assets/jss/ev.jsx";
import customCheckboxRadioSwitch from "assets/jss/ev/customCheckboxRadioSwitch.jsx";
import typographyStyle from "assets/jss/ev/views/componentsSections/typographyStyle.jsx";

const mapSection = theme => ({
  container,
  ...customCheckboxRadioSwitch,
  ...typographyStyle,
  section: {
    padding: "49px 0 0 0",
    height: "900px",
    marginLeft: drawerWidth,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      height: "600px"
    },
    [theme.breakpoints.down("sm")]: {
      height: "600px"
    },
    [theme.breakpoints.down("md")]: {
      height: "700px"
    },
    [theme.breakpoints.down("lq")]: {
      height: "800px"
    }
  },
  title: {
    ...title,
    marginBottom: "50px",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  description: {
    color: "#999",
    textAlign: "center"
  },
  textCenter: {
    textAlign: "center"
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px"
  },
  typo: {
    marginBottom: "40px",
    position: "relative",
    width: "100%"
  }
});

export default mapSection;
