import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Footer from "components/Footer/Footer.jsx";

// components for this layout
import mainlayoutStyle from "assets/jss/ev/layouts/mainLayout.jsx";

const MainLayout = ({ children, classes }) => (
  <div className={classes.root}>
    {children}
    <Footer />
  </div>
);

export default withStyles(mainlayoutStyle)(MainLayout);
