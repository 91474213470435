import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

//  core components
import MapInfo from "components/Map/MapInfo.jsx";

import mapStyle from "assets/jss/ev/components/mapStyle.jsx";

// Import map components
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import MapMarker from "components/Map/MapMarker.jsx";

class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isSelected: false };
  }

  handleClickOpen = () => {
    this.setState({ isSelected: true });
  };

  render() {
    const { actions, playlists } = this.props;
    const { isSelected } = this.state;
    const ottawa = { lat: 45.4232921, lng: -75.6959235 };

    const randomCloseTo = (center, radius) => {
      var x0 = Number(center.lng);
      var y0 = Number(center.lat);

      var rd = radius / 111300;

      var u = Math.random();
      var v = Math.random();

      var w = rd * Math.sqrt(u);
      var t = 2 * Math.PI * v;
      var x = w * Math.cos(t);
      var y = w * Math.sin(t);

      var xp = x / Math.cos(y0);

      // console.log(center);
      // console.log({ lat: y + y0, lng: xp + x0 });
      return { lat: y + y0, lng: xp + x0 };
    };

    // somewhere in Ottawa
    const locateTheUnlocated = item => {
      let returnableItem = item;
      let randomItemLocation = {};
      const randomTimestamp = parseInt(Math.random() * 1000000000, 13);
      if (
        returnableItem.location === "refused" ||
        !returnableItem.location.lat
      ) {
        const loc = randomCloseTo(ottawa, 1100);
        randomItemLocation = {
          lat: loc.lat,
          long: loc.lng,
          timestamp: randomTimestamp,
          randomized: true
        };
        returnableItem.markerLocation = randomItemLocation;
      } else {
        const locationVar = {
          lat: Number(item.location.lat),
          lng: Number(item.location.long)
        };
        const loc1 = randomCloseTo(locationVar, 700);
        randomItemLocation = {
          lat: loc1.lat,
          long: loc1.lng,
          randomized: false
        };
        returnableItem.markerLocation = randomItemLocation;
      }
      // console.log(returnableItem);
      return returnableItem;
    };

    const iconFromFactory = (type, enclosure) => {
      const markerHeight = 22;
      const markerWidth = 17;
      if (type === "action") {
        return {
          scaledSize: {
            width: markerWidth,
            height: markerHeight
          },
          url:
            enclosure.state === "verified"
              ? "https://firebasestorage.googleapis.com/v0/b/mc2-equalvoice.appspot.com/o/web%2Ficons%2Fpin_veriied_action.png?alt=media&token=29c05a79-aecb-4133-bd93-e5b768497d9c"
              : "https://firebasestorage.googleapis.com/v0/b/mc2-equalvoice.appspot.com/o/web%2Ficons%2Fpin_action.png?alt=media&token=bd7584da-7cef-4500-ae61-ed3efd271dc4"
        };
      } else if (type === "playlist") {
        return {
          scaledSize: {
            width: markerWidth,
            height: markerHeight
          },
          url:
            enclosure.author === "Equal Voice"
              ? "https://firebasestorage.googleapis.com/v0/b/mc2-equalvoice.appspot.com/o/web%2Ficons%2Fpin_playlist.png?alt=media&token=13ae6372-8950-45e6-a1eb-128145b9acd0"
              : "https://firebasestorage.googleapis.com/v0/b/mc2-equalvoice.appspot.com/o/web%2Ficons%2Fpin_custom_playlist.png?alt=media&token=14e0cf9c-fee8-409c-8a50-3ef6cfab1757"
        };
      }
    };

    const detailPopupFromFactory = (type, data) => {
      return <MapInfo type={type} data={data} />;
    };

    const MapContainerView = withScriptjs(
      withGoogleMap(() => {
        return (
          <GoogleMap
            defaultZoom={10}
            defaultCenter={randomCloseTo(ottawa, 100)}
          >
            {actions.map(actionItem => {
              const action = locateTheUnlocated(actionItem);
              const key = parseInt(Math.random() * 1000000000, 13);
              return (
                <MapMarker
                  action={action}
                  isSelected={isSelected}
                  position={{
                    lat: Number(action.markerLocation.lat),
                    lng: Number(action.markerLocation.long)
                  }}
                  randomized={action.location.randomized}
                  icon={iconFromFactory("action", action)}
                  key={key}
                  onClick={this.handleClickOpen}
                >
                  {detailPopupFromFactory("action", action)}
                </MapMarker>
              );
            })}
            {playlists.map(playlistItem => {
              const playlist = locateTheUnlocated(playlistItem);
              const key = parseInt(Math.random() * 1000000000, 13);
              return (
                <MapMarker
                  playlist={playlist}
                  isSelected={isSelected}
                  position={{
                    lat: Number(playlist.markerLocation.lat),
                    lng: Number(playlist.markerLocation.long)
                  }}
                  icon={iconFromFactory("playlist", playlist)}
                  key={key}
                  onClick={this.handleClickOpen}
                >
                  {detailPopupFromFactory("playlist", playlist)}
                </MapMarker>
              );
            })}
          </GoogleMap>
        );
      })
    );

    return <MapContainerView {...this.props} />;
  }
}

MapContainer.defaultProps = {
  size: "full"
};

MapContainer.propTypes = {
  size: PropTypes.string,
  actions: PropTypes.array.isRequired,
  playlists: PropTypes.array.isRequired
};

export default withStyles(mapStyle)(MapContainer);
