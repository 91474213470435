import { title, container } from "assets/jss/ev.jsx";
import customCheckboxRadioSwitch from "assets/jss/ev/customCheckboxRadioSwitch.jsx";
import typographyStyle from "assets/jss/ev/views/componentsSections/typographyStyle.jsx";

const mapStyle = theme => ({
  container,
  ...customCheckboxRadioSwitch,
  ...typographyStyle,
  section: {
    /* marginTop: "180px", */ // turning off for unfixed header
    [theme.breakpoints.down("sm")]: {
      /* marginTop: "116px", */ // turning off for unfixed header
    }
  },
  title: {
    ...title,
    marginBottom: "50px",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  description: {
    color: "#999",
    textAlign: "center"
  },
  textCenter: {
    textAlign: "center"
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px"
  },
  typo: {
    marginBottom: "40px",
    position: "relative",
    width: "100%"
  },
  cardActionArea: {
    width: "100%"
  },
  cardMedia: {
    height: "400px"
  },
  dialogTitle: {
    backgroundColor: "#d9dfe4"
  },
  dialogContent: {
    padding: 0
  }
});

export default mapStyle;
