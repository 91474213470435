/*eslint-disable*/
import React from "react";
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import { withStyles } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import footerStyle from "assets/jss/ev/components/footerStyle.jsx";
import brandLogoEn from "assets/img/ev-logo.png";
import brandLogoFr from "assets/img/ev-logo-fr.png";

/**
<!--
<nav className={classes.footer}>
	<ul>
		<li><a href="https://equalvoice.nationbuilder.com/join">Join</a></li>
		<li><a href="https://equalvoice.nationbuilder.com/contact">Contact</a></li>
		<li><a href="https://equalvoice.nationbuilder.com/donate">Donate</a></li>
	</ul>
</nav>
--
*/

function Footer({ ...props }) {
  const { classes, whiteFont, intl } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });

  const getBrandLogo = () => {
    if (intl.locale === "fr") {
      return brandLogoFr;
    } else {
      return brandLogoEn;
    }
  }
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <Grid container spacing={8}>
          <Grid item spacing={8} sm={12} md={6} container>
            <Grid item sm={12} md={6} className={classes.logoContainer}><img className={classes.logo} src={getBrandLogo()}/></Grid>
            <Grid item sm={12} md={6}>
              <h4 className={classes.text}>
                <FormattedMessage id="Footer.text" />
              </h4>
            </Grid>
          </Grid>
          <Grid item sm={12} md={6} spacing={8} container className={classes.buttonArea}>
            <Button aria-label="Join" className={classes.button} href="https://equalvoice.nationbuilder.com/join"><FormattedMessage id="Footer.join" /></Button>
            <Button aria-label="Contact" className={classes.button} href="https://equalvoice.nationbuilder.com/contact"><FormattedMessage id="Footer.contact" /></Button>
            <Button aria-label="Donate" className={classes.button} href="https://equalvoice.nationbuilder.com/donate"><FormattedMessage id="Footer.donate" /></Button>
            <div className={classes.copyright}>
              &copy; {1900 + new Date().getYear()} , <FormattedMessage id="Footer.copyright" />{" "}
              <a
                href="http://www.equalvoice.ca/privacy_policy"
                className={aClasses}
                target="_blank"
              >
                <FormattedMessage id="Footer.privacy" />
              </a>{" "}
            </div>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool,
  intl: intlShape,
};

export default withStyles(footerStyle)(injectIntl(Footer));
