import React from "react";
// react components for routing the app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import exampleStyle from "assets/jss/ev/views/componentsSections/exampleStyle.jsx";

import exampleTopMenu from "assets/img/example-top-menu.png";
import exampleUI from "assets/img/example-ui.png";

class SectionExamples extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div id="examples" className={classes.section}>
        <div className={classes.container}>
          <div className={classes.title}>
            <h2>Example UI "Designs"</h2>
          </div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <Link to="landing-page" className={classes.link}>
                <img
                  src={exampleUI}
                  alt="..."
                  className={
                    classes.imgRaised +
                    " " +
                    classes.imgRounded +
                    " " +
                    classes.imgFluid
                  }
                />
                <Button color="primary" size="lg" simple>
                  View home page
                </Button>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Link to="landing-page" className={classes.link}>
                <img
                  src={exampleTopMenu}
                  alt="..."
                  className={
                    classes.imgRaised +
                    " " +
                    classes.imgRounded +
                    " " +
                    classes.imgFluid
                  }
                />
                <Button color="primary" size="lg" simple>
                  View home page
                </Button>
              </Link>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(exampleStyle)(SectionExamples);
