/*eslint-disable*/
import React from "react";
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons

// core components
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/ev/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          href="https://equalvoice.nationbuilder.com/learn"
          target="_blank"
          color="white"
          text="text"
          size="lg"
          disableRipple
          className={classes.navLink}
        >
          <FormattedMessage id="Header.learn" />
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="https://equalvoice.nationbuilder.com/connect"
          target="_blank"
          color="white"
          text="text"
          size="lg"
          disableRipple
          className={classes.navLink}
        >
          <FormattedMessage id="Header.connect" />
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="https://equalvoice.nationbuilder.com/act"
          target="_blank"
          color="white"
          text="text"
          size="lg"
          disableRipple
          className={classes.navLink}
        >
          <FormattedMessage id="Header.act" />
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="https://equalvoice.nationbuilder.com/about"
          target="_blank"
          color="white"
          text="text"
          size="lg"
          disableRipple
          className={classes.navLink}
        >
          <FormattedMessage id="Header.about" />
        </Button>
      </ListItem>
    </List>
  );
}

HeaderLinks.propTypes = {
  intl: intlShape.isRequired,
};

export default withStyles(headerLinksStyle)(injectIntl(HeaderLinks));
