import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";

// @material-ui/icons

import mapStyle from "assets/jss/ev/components/mapStyle.jsx";
import { Marker } from "react-google-maps";

const {
  MarkerClusterer
} = require("react-google-maps/lib/components/addons/MarkerClusterer");

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class MapMarker extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isSelected: props.isSelected };
  }

  handleClose = () => {
    this.setState({ isSelected: false });
  };

  onMarkerClustererClick = () => markerClusterer => {
    const clickedMarkers = markerClusterer.getMarkers();
    console.log(`Current clicked markers length: ${clickedMarkers.length}`);
    console.log(clickedMarkers);
  };

  render() {
    const { position, icon } = this.props;
    const { isSelected } = this.state;
    return (
      <MarkerClusterer
        averageCenter
        enableRetinaIcons
        gridSize={120}
        defaultMinimumClusterSize={2}
        zoomOnClick={true}
        onClick={this.onMarkerClustererClick}
      >
        <Marker
          position={position}
          icon={icon}
          onClick={() => this.setState({ isSelected: true })}
        >
          {this.state.isSelected && (
            <Dialog
              fullWidth
              open={isSelected}
              onClose={this.handleClose}
              TransitionComponent={Transition}
            >
              {this.props.children}
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Marker>
      </MarkerClusterer>
    );
  }
}

MapMarker.defaultProps = {
  isSelected: false
};

MapMarker.propTypes = {
  icon: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  action: PropTypes.object,
  playlist: PropTypes.object,
  isSelected: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default withStyles(mapStyle)(MapMarker);
