import { title, container, primaryColor } from "assets/jss/ev.jsx";

const footerStyle = theme => ({
  text: {
    ...title,
    color: "#333",
    fontSize: "18px",
    fontWeight: 400,
    width: "262px", // observation
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: "auto"
    }
  },
  button: {
    backgroundColor: "#783875",
    color: "#fff",
    borderRadius: "20px",
    marginRight: "20px",
    width: "calc(33.3% - 20px)",
    padding: "0.5rem 1rem",
    fontSize: "22px",
    "&last-item": {
      marginRight: "0px"
    },
    "&:hover": {
      backgroundColor: "#783875",
      color: "#fff"
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      marginRight: "auto",
      width: "100%",
      marginBottom: "24px"
    }
  },
  buttonArea: {
    marginTop: "80px",
    display: "block",
    paddingLeft: "36px!important"
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right!important"
  },
  footer: {
    padding: "67px 0",
    textAlign: "center",
    display: "flex",
    zIndex: "1100",
    position: "relative",
    backgroundColor: "#d9dfe4",
    bottom: 0
  },
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  container: {
    ...container,
    paddingLeft: 0,
    "@media (min-width: 576px)": {
      maxWidth: "540px"
    },
    "@media (min-width: 768px)": {
      maxWidth: "750px"
    },
    "@media (min-width: 992px)": {
      maxWidth: "970px"
    },
    "@media (min-width: 1200px)": {
      maxWidth: "1170px"
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "15px"
    }
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px"
  },
  copyright: {
    textAlign: "left",
    fontSize: "18px",
    fontWeight: "400",
    marginTop: "45px",
    marginLeft: "5px"
  },
  logoContainer: {
    margin: "auto"
  },
  logo: {
    maxWidth: "228px"
  }
});
export default footerStyle;
