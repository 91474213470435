import { title, drawerWidth } from "assets/jss/ev.jsx";

const landingPageStyle = theme => ({
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    borderRadius: 0,
    marginLeft: 0,
    marginBottom: 0, // added for unfixed header
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("xs")]: {
      display: "none"
    }
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.up("xs")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  container: {
    zIndex: "12",
    display: "flex"
  },
  header: {
    color: "#FFFFFF"
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  parallax: {
    height: "48vh",
    maxHeight: "300px",
    alignItems: "top"
  },
  brandImage: {
    maxWidth: "365px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "200px"
    }
  }
});

export default landingPageStyle;
