/*eslint-disable*/
import React from "react";
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons

// core components
import Button from "components/CustomButtons/Button.jsx";

import sidebarLinksStyle from "assets/jss/ev/components/sidebarLinksStyle.jsx";

function SidebarLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link to="/">
          <Button
            href="/"
            color="white"
            text="text"
            size="lg"
            disableRipple
            className={classes.navLink}
          >
            <FormattedMessage id="Sidebar.linkOne" />
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="#/leaderboard">
          <Button
            href="#/leaderboard"
            color="white"
            text="text"
            size="lg"
            disableRipple
            className={classes.navLink}
          >
            <FormattedMessage id="Sidebar.linkTwo" />
          </Button>
        </Link>
      </ListItem>
    </List>
  );
}

SidebarLinks.propTypes = {
  intl: intlShape.isRequired,
};

export default withStyles(sidebarLinksStyle)(injectIntl(SidebarLinks));
