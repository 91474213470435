import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

import mapStyle from "assets/jss/ev/components/mapStyle.jsx";

// Import map components
import googleMapConfigKey from "config/map";
import MapContainer from "components/Map/MapContainer.jsx";

/* 
  @@ DEVELOPER NOTE on Map implementation @@
    TO see a more robust up to date implementation the map component...
    Check out https://gitlab.com/mc2labs/mc2-gdd-2018-map/blob/web/src/components/Map/Map.js

  @@ DEVELOPER NOTE on filter implementation @@
    To see some basic examples of Krassi's original filtering code...
    Check out https://gitlab.com/krassik/learn-map/blob/multifacet/src/index.js

    To see how we will accomplish this using our re-base Firebase/Firestore implementation...
    Check out the docs about bindCollection() and using query={} at https://github.com/tylermcginnis/re-base#bindcollectionreforpath-options
  
    @@ NOTE: @@ We should be putting our fireStoreBase() code in LandingPage/Sections/MapSection and 
      passing down as props into this component, keeping this component pure as possible.
      See https://reactjs.org/docs/components-and-props.html#props-are-read-only and
        https://medium.com/@joshblack/stateless-components-in-react-0-14-f9798f8b992d for more info
        on Pure / Stateless Functional components

  @@ DEVELOPER NOTE @@  on testData
    Once we get the actions, playlists, etc moving into this component as props, we need to 
    remove the testData prop, randomElement() function, randomIcons() function and replace
    with icons from action data with icon pins. To see how this was done in GDD, see
    https://gitlab.com/mc2labs/mc2-gdd-2018-map/blob/web/src/components/Map/Map.js
*/

class NewMap extends React.Component {
  constructor(props) {
    super(props);
    this.filterRef = React.createRef();
    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler() {
    setTimeout(async () => {
      // const json = JSON.parse(this.filterRef.current.value);

      // await this.props.model.multiFilter(json);

      JSON.parse(this.filterRef.current.value);

      this.setState({
        // markers: this.markersForActions(this.props.model.filtered)
      });
    }, 500);
  }

  render() {
    const { actions, playlists, classes } = this.props;
    const googleMapApi = `${googleMapConfigKey}`;

    return (
      <div className={classes.section}>
        <MapContainer
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapApi}&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `650px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          actions={actions}
          playlists={playlists}
        />
      </div>
    );
  }
}

NewMap.defaultProps = {
  size: "full"
};

NewMap.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.string,
  actions: PropTypes.array.isRequired,
  playlists: PropTypes.array.isRequired,
};

export default withStyles(mapStyle)(NewMap);
