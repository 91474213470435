import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomDrawer from "components/Drawer/Drawer.jsx";
import SidebarLinks from "components/Sidebar/SidebarLinks.jsx";

import sidebarStyle from "assets/jss/ev/components/sidebarStyle.jsx";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classicModal: false
    };
  }

  handleClick = opened => {
    this.setState({ [opened]: !this.state[opened] });
  };

  render() {
    const {
      classes,
      filterDrawerOpen,
      handleDrawerToggle,
      variant
    } = this.props;

    const DrawerTitle = () => <h3 className={classes.title}>Main Menu</h3>;

    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <div className={classes.container}>
              <Paper className={classes.paper}>
                <GridContainer>
                  <GridItem>
                    <CustomDrawer
                      open={filterDrawerOpen}
                      onClose={handleDrawerToggle}
                      variant={variant}
                      anchor={"left"}
                      links={<SidebarLinks />}
                      classes={{
                        paper: classes.drawerPaper
                      }}
                      className={classes.drawer}
                      DrawerTitle={DrawerTitle}
                      PaperProps={{ elevation: 0 }}
                    />
                  </GridItem>
                </GridContainer>
              </Paper>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  color: "gray"
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  filterDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
  isChecked: PropTypes.func.isRequired,
  handleCheck: PropTypes.func.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
