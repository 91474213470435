import React from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components

import landingPageStyle from "assets/jss/ev/views/landingPage.jsx";
import brandLogoEn from "assets/img/ev-logo-full.png";
import brandLogoFr from "assets/img/ev-logo-full-fr.png";
// Sections for this page
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import TopBarLinks from "components/Header/TopBarLinks.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import MapSection from "./Sections/MapSection.jsx";

const dashboardRoutes = [];

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterDrawerOpen: false,
      checkedFilters: []
    };
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
  }

  /* 
    @@ DEVELOPER NOTE on MapFilters implementation @@
      To see some basic examples of Krassi's original filtering code...
      Check out https://gitlab.com/krassik/learn-map/blob/multifacet/src/index.js

      To see how we will accomplish this using our re-base Firebase/Firestore implementation and queries...
      Check out the docs about bindCollection() and using query={} at 
      https://github.com/tylermcginnis/re-base#bindcollectionreforpath-options AND
      https://github.com/tylermcginnis/re-base#using-firestore-queries
  
    @@ NOTE: @@ We should be putting our fireStoreBase() code for filtering here in LandingPage and 
      passing down as props into the MapFilters component, keeping this component where data is loaded and passed,
      and MapFilters pure.

  */

  handleDrawerToggle() {
    this.setState({ filterDrawerOpen: !this.state.filterDrawerOpen });
  }

  getBrandLogo() {
    if (this.props.intl.locale === "fr") {
      return brandLogoFr;
    } else {
      return brandLogoEn;
    }
  }

  render() {
    const { classes } = this.props;
    const { filterDrawerOpen, checkedFilters } = this.state;

    const isChecked = key => {
      return checkedFilters.indexOf(key) > -1;
    };

    const handleCheck = (evt, filter) => {
      if (isChecked(filter)) {
        this.setState({
          checkedFilters: checkedFilters.filter(i => i !== filter)
        });
        console.log("Checked: ");
        console.log(checkedFilters.filter(i => i !== filter));
      } else {
        this.setState({
          checkedFilters: [...checkedFilters, filter]
        });
        console.log("notChecked: ");
        console.log([...checkedFilters, filter]);
      }
    };

    const brandImage = (
      <img
        src={this.getBrandLogo()}
        alt="Equal Voice"
        className={classNames(classes.imgFluid, classes.brandImage)}
      />
    );
    return (
      <div>
        <Header
          classes={{
            title: classes.header,
            appBar: classNames(classes.appBar, {
              [classes.appBarShift]: filterDrawerOpen
            })
          }}
          routes={dashboardRoutes}
          brand={brandImage}
          leftLinks={<HeaderLinks />}
          topBarLinks={<TopBarLinks />}
          handleMenuToggle={this.handleDrawerToggle}
          menuOpen={filterDrawerOpen}
        />
        <nav className={classes.drawer}>
          {/* The implementation can be swap with js to avoid SEO duplication of links. */}
          <Sidebar
            filterDrawerOpen={filterDrawerOpen}
            handleDrawerToggle={this.handleDrawerToggle}
            className={classes.drawer}
            open
            variant="persistent"
            checked={checkedFilters}
            isChecked={isChecked}
            handleCheck={handleCheck}
          />
        </nav>
        <div
          className={classNames(classes.content, {
            [classes.contentShift]: filterDrawerOpen
          })}
        >
          <MapSection filters={checkedFilters} />
        </div>
      </div>
    );
  }
}

LandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  container: PropTypes.object,
  filters: PropTypes.array,
  intl: intlShape
};

export default withStyles(landingPageStyle)(injectIntl(LandingPage));
